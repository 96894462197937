import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/Layout.jsx'
import LayoutWithSideMenu from '../components/LayoutWithSideMenu'
import Container from '../components/Container'

export default({
  data
}) => {
  const troubles = data.allMarkdownRemark.edges.map(edge => edge.node.frontmatter)
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const breadcrumbs = [
    {
      name: 'Главная',
      path: '/'
    },
    {
      name: 'Типичные неисправности',
      path: '/neispravnosti'
    },
    {
      name: frontmatter.name,
      path: frontmatter.path
    }
  ]
  return (
    <Layout>
      <Container>
        <Helmet>
          <title>{frontmatter.title}</title>
          <meta name="description" content={frontmatter.description} />
        </Helmet>
        <LayoutWithSideMenu heading={frontmatter.heading} menu={troubles} breadcrumbs={breadcrumbs}>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </LayoutWithSideMenu>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query malfunctionPageByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        name
        path
        title
        heading
        description
      }
    }

    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/malfunctions/" } },
      sort: {order: DESC, fields: [frontmatter___date]}) {
      edges {
        node {
          frontmatter {
            name
            path
          }
        }
      }
    }
  }
`
